import React from 'react'
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import '../src/style/global.css'

import Home from './components/Home'
import NotFound from './components/NotFound';

import Reviews from './components/Reviews';
import ReviewId from './components/ReviewId';

import About from './components/About'
import RouteId from './components/RouteId'
import UserService from './components/UserService'
import UserServiceNew from './components/UserServiceNew';

import ServiceId from './components/ServiceId';
import UserId from './components/UserId';
import Profile from './components/Profile';

import UserIdNewRoute from './components/UserIdNewRoute';
import UserIdRoutesId from './components/UserIdRoutesId';
import UserIdPlannedRoutes from './components/UserIdPlannedRoutes';
import UserIdRoutesCompleted from './components/UserIdRoutesCompleted';
import UserIdRoutesCompletedId from './components/UserIdRoutesCompletedId';
import UserIdRoutesCompletedIdReview from './components/UserIdRoutesCompletedIdReview';


import ServiceIdCustomers from './components/ServiceIdCustomers';
import ServiceIdCustomersNew from './components/ServiceIdCustomersNew';
import ServiceIdCustomersId from './components/ServiceIdCustomersId';
import ServiceIdTransportations from './components/ServiceIdTransportations';
import ServiceIdTransportationsId from './components/ServiceIdTransportationsId';
import ServiceIdTransportationsCompleted from './components/ServiceIdTransportationsCompleted';
import ServiceIdTransportationsCompletedId from './components/ServiceIdTransportationsCompletedId';

import Cloud from './components/Cloud';
import CloudId from './components/CloudId'

function App() {
  return (
    <Router>
      <Routes>
        <Route index element = {<Home />} />
        <Route path='/reviews/' element={<Reviews />} />
        <Route path='/reviews/:rv_id/' element={<ReviewId />} />
        <Route path='/about/' element={<About />} />
        <Route path='/user/' element={<UserService />} />
        <Route path='/user/new/' element={<UserServiceNew />} />
        <Route path='/user/:u_id/' element={<UserId />} />
        <Route path='/user/:u_id/profile/' element={<Profile />} />
        <Route path='/user/:u_id/new-drive/' element={<UserIdNewRoute />} />
        <Route path='/user/:u_id/drives/' element={<UserIdPlannedRoutes />} />
        <Route path='/user/:u_id/drives/completed/' element={<UserIdRoutesCompleted />} />
        <Route path='/user/:u_id/drives/completed/:r_id/' element={<UserIdRoutesCompletedId />} />
        <Route path='/user/:u_id/drives/completed/:r_id/review/' element={<UserIdRoutesCompletedIdReview />} />
        <Route path='/user/:u_id/drives/:r_id/' element={<UserIdRoutesId/>} />
        <Route path='/service/' element={<UserService />} />
        <Route path='/service/new/' element={<UserServiceNew />} />
        <Route path='/service/:ts_id/' element={<ServiceId />} />
        <Route path='/service/:ts_id/cloud/' element={<Cloud />} />        
        <Route path='/service/:ts_id/cloud/:r_id/' element={<CloudId />} />        
        <Route path='/service/:ts_id/profile/' element={<Profile />} />
        <Route path='/service/:ts_id/customers/' element={<ServiceIdCustomers />} />
        <Route path='/service/:ts_id/customers/new/' element={<ServiceIdCustomersNew />} />
        <Route path='/service/:ts_id/customers/:u_id/' element={<ServiceIdCustomersId />} />
        <Route path='/service/:ts_id/transportations/' element={<ServiceIdTransportations />} />
        <Route path='/service/:ts_id/transportations/completed/' element={<ServiceIdTransportationsCompleted />} />
        <Route path='/service/:ts_id/transportations/completed/:r_id/' element={<ServiceIdTransportationsCompletedId />} />
        <Route path='/service/:ts_id/transportations/:r_id/' element={<ServiceIdTransportationsId />} />
        <Route path='/:r_id/' element={<RouteId />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App
