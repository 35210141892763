import React from 'react'

import '../style/error.css'
import NavBar from '../subcomponents/NavBar';

const errorData = {
    nav_references: [
        {
            name: 'Home',
            url: '/'
        }
    ]
};

function NotFound() {
    return (
        <div className='error-container'>
            <header className='error-title'>
                <h1>Error: 404</h1>
            </header>
            <img src="/images/CabCloudLogo.WebP" alt="Logo"/>
            <NavBar references={[]}/>
            <div className='error-message'>
                <p>Page not found.</p>
            </div>
        </div>
    );
}

export default NotFound;