import {React, useState, useEffect} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

import '../style/routeid.css'
import NavBar from '../subcomponents/NavBar'
import Info from '../subcomponents/Info'
import ReviewBox from '../subcomponents/ReviewBox'

function RouteId() {
    const location = useLocation();
    const routeid_url = location.state?.extraParam;
    const [routeIdData, setRouteIdData] = useState(null)
    const navigate = useNavigate();
    useEffect(() => {
        if (!routeid_url) {
            navigate('/');
            return;
        }
        fetch(routeid_url, {
            headers: {
              accept: "application/json"
            }
        }).then(
            response => response.json()
        ).then(
            data => {
                if (data.redirect) {
                    navigate(`/reviews/${data.id}/`, {state: { extraParam: data.redirect}});
                    return;
                } else {
                    setRouteIdData(data);
                }
            }
        )
    }, [routeid_url,navigate]);

    if (!routeIdData) {
        return (
            <p>Loading...</p>
        )
    } else {
        if (routeIdData.completed) {
            return (
                <div className='routeid-container'>
                    <header className='routeid-title'>
                        <h1>Review</h1>
                    </header>
                    <img src="/images/CabCloudLogo.WebP" alt="Logo"></img>
                    <NavBar references={routeIdData.nav_references}/>
                    <main className='routeid-drive'>
                        <ReviewBox form={routeIdData.forms[0]} current_api_url={routeid_url}/>
                    </main>
                </div>
            )
        }
        return (
            <div className='routeid-container'>
                <header className='routeid-title'>
                    <h1>Route</h1>
                </header>
                <img src="/images/CabCloudLogo.WebP" alt="Logo"/>
                <NavBar references={routeIdData.nav_references}/>
                <main className='routeid-drive'>
                    <Info form={routeIdData.forms[0]} style_input='routeid-input' style_buttons='' style_button='routeid-button' style_label='routeid-label-text' p={true} methods={[]}/>
                </main>
            </div>
        );
    }
}

export default RouteId;