import { React, useState } from "react";
import { useNavigate } from 'react-router-dom';
import '../style/login.css'


function Login(props) {
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const navigate = useNavigate();
    const translate = {'u_id': "ID", 'ts_id': "ID", 'r_id': "ID", 'GET': "Find", 'POST': "Submit", 'PATCH': "Update", 'DELETE': "Delete", "address_org": "Pickup address", "address_dest": "Dropoff address", "tel": "Tel.", "timestamp_of_pickup": "Pick-Up Time", "email": "E-mail","comment": "Comments", "driveid": "DriveID", "password": "Password",'name': "Name", "first_name": "First Name", "last_name": "Last Name", "address": "Address", "mutas": "Mutas", "transportation_service": "Transportation Type", "accepted": "Accepted", "standard": "Standard Transport", "wheelchair": "Wheelchair Transport", "ambulance": "Ambulance Transport"};
    const handleSubmit = async (e) => {
        e.preventDefault();

        // reset errors
        setEmailError('');
        setPasswordError('');
        
        // get values
        const formData = new FormData(e.target);
        const email = formData.get('email');
        const password = formData.get('password');
        try {
            await fetch(props.current_api_url, {
                method: "POST",
                body: JSON.stringify({email, password}),
                credentials: "include",
                headers: { 'Content-Type': 'application/json' }
            }).then(response => {
                return response.json();
            }).then(data => {
                if (data.errors) {
                    if (window.location.pathname.split('/').includes("service")) {
                        setEmailError(data.errors.ts_email);
                        setPasswordError(data.errors.ts_password);
                    } else {
                        setEmailError(data.errors.u_email);
                        setPasswordError(data.errors.u_password);
                    }                    
                }
                if (data.objectid) {
                    const link = props.objects.find(link => link.includes(data.objectid));
                    navigate(`${window.location.pathname}${data.objectid}/`,{ state: { extraParam: link} });
                }
                
            });
        } catch (err) {
            console.log(err);
        }
    }
    let error;
    return (
        <form onSubmit={(e) => handleSubmit(e)}>
            {Object.entries(props.form.required_fields).map(([key,value],index) => {
                if (key === "email") {
                    error = emailError;
                } else {
                    error = passwordError;
                }
                return (
                    <div key={index}>
                        <label htmlFor={key}  className='login-label-text'>{translate[key]+"*"} </label><input className='login-input' id={key} name={key} type={key} placeholder={`Enter ${translate[key]}`} required/>
                        <div className="inlog-error">{error}</div>    
                    </div>
                );
            })}
            <button className='login-button' type='submit'>Log In</button>
        </form>
    );
}

export default Login;