import {React, useState, useEffect} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import '../style/about.css'

import NavBar from '../subcomponents/NavBar'

function About() {
    const location = useLocation();
    const about_url = location.state?.extraParam;
    const [aboutData, setAboutData] = useState(null)
    const navigate = useNavigate();

    useEffect(() => {
        if (!about_url) {
            navigate('/');
            return;
        }
        fetch(about_url, {
            headers: {
              accept: "application/json"
            }
        }).then(
            response => response.json()
        ).then(
            data => {
                setAboutData(data);
            }
        )
    }, [about_url,navigate]);

    if (!aboutData) {
        return (
            <p>Loading...</p>
        )
    } else {
        return (
            <div className="about-container">
                <header className="title-about">
                    <h1>Who are we?</h1>
                </header>
                <img src="/images/CabCloudLogo.WebP" alt="Logo"></img>
                <NavBar references={aboutData.nav_references}></NavBar>
                <img src="/images/Matthieu.WebP" alt="Matt" className="icon2"/>
                <img src="/images/Stijn.WebP" alt="Stijn" className="icon1"/>
                <div className="about">
                <p> CabCloud is more than a service; it's a commitment to mobility
                <br/>
                for all. Founded on the belief that transportation is a fundamental
                <br/>
                right, we've dedicated ourselves to creating a platform that serves the entire community, regardless of individual mobility needs.
                </p><p> The inception of CabCloud is deeply rooted in personal 
                <br/>
                experience. One of our founders, after facing repeated challenges
                <br/>
                in communicating with taxi services, recognized a widespread
                <br/>
                issue: the transportation industry for disabled people was riddled with inefficiencies and misunderstandings that made simple trips
                <br/>
                unnecessarily complicated, with lots of issues arising.
                </p><p> These experiences were not just minor inconveniences; they were
                <br/>
                barriers that impacted daily life. The inability to go anywhere
                <br/>
                without going through a painstaking process to book one simple taxi drive made it very clear to him and his friend
                <br/>
                (and co-founder) that things had to change.
                </p><p>And so, CabCloud was born. With our heads in the clouds, dreaming to revolutionize the transportation scene.</p>
                </div>
            </div>
        );
    }
}

export default About;