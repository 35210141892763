import {React, useState, useEffect} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

import NavBar from '../subcomponents/NavBar'
import Form from '../subcomponents/Form'

import '../style/newDrive.css'

function UserIdNewRoute() {
    const location = useLocation();
    const userid_new_route_url = location.state?.extraParam;
    const [userIdNewRouteData, setUserIdNewRouteData] = useState(null)
    const navigate = useNavigate();
    useEffect(() => {
        if (!userid_new_route_url) {
            navigate('/');
            return;
        }
        fetch(userid_new_route_url, {
            credentials: 'include',
            headers: {
              accept: "application/json"
            }
        }).then(async response => {
            const data = await response.json();
            if (response.status === 400) {
                navigate('/');
            } else {
                setUserIdNewRouteData(data);
            }
        });
    }, [userid_new_route_url,navigate]);

    if (!userIdNewRouteData) {
        return (
            <p>Loading...</p>
        )
    } else {
        return (
            <div className='userid-newdrive-container'>
                <header className='userid-newdrive-title'>
                    <h1>Plan a new drive</h1>
                </header>
                <img src="/images/CabCloudLogo.WebP" alt="Logo"/>
                <NavBar references={userIdNewRouteData.nav_references}/>
                <main className='userid-newdrive-new-drive'>
                    <Form form={userIdNewRouteData.forms[0]} style_input='userid-newdrive-input' style_button='userid-newdrive-button' style_label='userid-newdrive-label' style_form='' current_api_url={userid_new_route_url} p={true}/>
                </main>
            </div>
        );
    }
}

export default UserIdNewRoute;