import { React } from 'react'
import { useNavigate } from 'react-router-dom';

function ReviewBox(props) {
    const navigate = useNavigate();
    const translate = {'u_id': "ID", 'ts_id': "ID", 'r_id': "ID", 'GET': "Find", 'POST': "Submit", 'PATCH': "Update", 'DELETE': "Delete", "address_org": "Pickup address", "address_dest": "Dropoff address", "tel": "Tel.", "timestamp_of_pickup": "Pick-Up Time", "email": "E-mail","comment": "Comments", "driveid": "DriveID", "password": "Password",'name': "Name", "first_name": "First Name", "last_name": "Last Name", "address": "Address", "mutas": "Mutas", "transportation_service": "Transportation Type", "accepted": "Accepted", "standard": "Standard Transport", "wheelchair": "Wheelchair Transport", "ambulance": "Ambulance Transport", "placed_by": "Name", "rating": "Rating", "content": "Comment"}; 
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const jsonData = {}
        for (const [name, value] of formData.entries()) {
            jsonData[name] = value
        }
        if (!jsonData.incognito) {
            jsonData["incognito"] = false;
        } else {
            jsonData["incognito"] = true;
        }
        fetch(`${props.current_api_url}`, {
            method:"POST",
            credentials: "include",
            headers: {
                'Content-type': 'application/json',
                accept: 'application/json'
            },
            body: JSON.stringify(jsonData)
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error("Error in response...");
            }
        }).then(data => {
            if (window.location.pathname.includes("user")) {
                window.location.reload();
            } else {
                navigate(`/reviews/${data.id}/`, {state: {extraParam: data.link}})
            }
        }).catch(error => {
            console.log(`Something went wrong: ${error}`);
        })
    }
    return (
        <form onSubmit={(e) => handleSubmit(e)}>
            {Object.entries(props.form.required_fields).map(([key,value],index) => {
                return(
                    <div key={index}>
                        <label className='routeid-label-text'>{translate[key]+"*"}</label>
                        <input id={key} name={key} className='routeid-input' defaultValue={value} readOnly></input>
                    </div>
                )
            })}
            {Object.entries(props.form.optional_fields).map(([key,value],index) => {
                return(
                    <div key={index}>
                        <label className='routeid-label-text'>{translate[key]}</label>
                        <textarea id={key} name={key} className='routeid-input' defaultValue={value}></textarea>
                    </div>
                )
            })}
            <div>
                <label className='routeid-label-text'>Rating </label>
                <select id="rating" name="rating" className='select-box'>
                    <option value={1}>★</option>
                    <option value={2}>★★</option>
                    <option value={3}>★★★</option>
                    <option value={4}>★★★★</option>
                    <option value={5}>★★★★★</option>
                </select>
            </div>
            <div>
                <label className='routeid-label-text'>Incognito</label>
                    <input id="incognito" name="incognito" className='checkbox' type='checkbox'/>
            </div>
            <button className='routeid-button' type='submit'>Place Review</button>
        </form>
    )

}

export default ReviewBox;