import {React, useState, useEffect} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

import NavBar from '../subcomponents/NavBar';
import Info from '../subcomponents/Info';
import ReviewBox from '../subcomponents/ReviewBox'

import '../style/reviewid.css'

function UserIdRoutesCompletedIdReview() {
  const location = useLocation();
  const userid_routes_completed_review_url = location.state?.extraParam;
  const [userIdRoutesCompletedIdReviewData, setUserIdRoutesCompletedReviewData] = useState(null)
  const navigate = useNavigate();
  useEffect(() => {
    if (!userid_routes_completed_review_url) {
      navigate('/');
      return;
    }
    fetch(userid_routes_completed_review_url, {
      credentials: 'include',
      headers: {
        accept: "application/json"
      }
    }).then(async response => {
      const data = await response.json();
      if (response.status === 400) {
        navigate('/');
        return;
      } else {
        setUserIdRoutesCompletedReviewData(data);
      }
    });
  }, [userid_routes_completed_review_url,navigate]);

  if (!userIdRoutesCompletedIdReviewData) {
    return (
      <p>Loading...</p>
    )
  } else {
    return (
        <div className='reviewid-container'>
            <header className='reviewid-title'>
                <h1>Review</h1>
            </header>
            <img src="/images/CabCloudLogo.WebP" alt="Logo"></img>
            <NavBar references={userIdRoutesCompletedIdReviewData.nav_references}/>
            <main className='reviewid-review'>
                {userIdRoutesCompletedIdReviewData.review ? 
                    <Info current_api_url={userid_routes_completed_review_url} form={userIdRoutesCompletedIdReviewData.forms[0]} style_input='reviewid-input' style_button='reviewid-button' style_label='reviewid-label-text' p={true} methods={[]}/> 
                :
                    <ReviewBox form={userIdRoutesCompletedIdReviewData.forms[0]} current_api_url={userid_routes_completed_review_url}/>
                }
            </main>
        </div>
    );
  }
}

export default UserIdRoutesCompletedIdReview;