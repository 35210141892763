import {React, useState} from 'react'
import { useNavigate } from 'react-router-dom';

function Info(props) {
    const translate = {'u_id': "ID", 'ts_id': "ID", 'r_id': "ID", 'GET': "Find", 'POST': "Submit", 'PATCH': "Update", 'DELETE': "Delete", "address_org": "Pickup address", "address_dest": "Dropoff address", "tel": "Tel.", "timestamp_of_pickup": "Pick-Up Time", "email": "E-mail","comment": "Comments", "driveid": "DriveID", "password": "Password",'name': "Name", "first_name": "First Name", "last_name": "Last Name", "address": "Address", "mutas": "Mutas", "transportation_service": "Transportation Type", "accepted": "Accepted", "standard": "Standard Transport", "wheelchair": "Wheelchair Transport", "ambulance": "Ambulance Transport", "placed_by": "Name", "rating": "Rating", "content": "Comment"} 
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        "id_": props.id,
        ...props.form.required_fields,
        ...props.form.optional_fields,
        ...props.form.special_fields
    });
    const [emailErrorData, setEmailErrorData] = useState("");

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };


    const handleClick = (m) => {
        if (m === "PATCH") {
            fetch(props.current_api_url,{
                method: m,
                credentials: "include",
                headers: {
                    'Content-type': 'application/json',
                    accept: 'application/json'
                },
                body: JSON.stringify(formData)
            }).then(response => {
                if (response.ok) {
                    setEmailErrorData('');
                    if (props.current_api_url.split('/').includes('cloud')) {
                        navigate(-1);
                    } else {
                        window.location.reload();
                    }
                } else {
                    setEmailErrorData('Please enter a valid email.');
                    console.log('Failed to update profile.');
                }
            }).catch(error => {
                window.location.reload();
                console.log(`Something went wrong: ${error}`);
            });
        } else if (m === "DELETE") {
            fetch(props.current_api_url,{
                method: m,
                credentials: "include",
                headers: {
                    'Content-type': 'application/json',
                    accept: 'application/json'
                }
            }).then(response => {
                if (response.ok && (props.current_api_url.split('/').includes("customers") || props.current_api_url.split('/').includes("transportations"))) {
                    navigate(-1);
                } else if (response.ok) {
                    navigate('/');
                }else {
                    throw new Error("Error in response...");
                }
            }).catch(error => {
                window.location.reload();
                console.log(`Something went wrong: ${error}`);
            });
        }
    }

    return (
        <form action={window.location.href}>
            {Object.entries(props.form.required_fields).map(([key,value],index) => {
                if (props.p) {
                    return (
                        <div key={index}>
                            <p className={props.style_label} htmlFor={key}>{translate[key]} </p>{props.profile ? (<input className={props.style_input} id={key} name={key} defaultValue={value} onChange={(e) => handleChange(e)}/>) : (<input className={props.style_input} id={key} name={key} value={value} readOnly/>)}
                            {key === "email" && (<div className='error'>{emailErrorData}</div>)}
                        </div>
                    )
                } else {
                    return (
                        <div key={index}>
                            <label htmlFor={key}>{translate[key]} className={props.style_label}</label>{props.profile ? (<input className={props.style_input} id={key} name={key} defaultValue={value} onChange={(e) => handleChange(e)}/>) : (<input className={props.style_input} id={key} name={key} value={value} readOnly/>)}
                            {key === "email" && (<div className='error'>{emailErrorData}</div>)}
                        </div>
                    )
                }
            })}
            {Object.entries(props.form.optional_fields).map(([key,value],index) => {
                if (props.p) {
                    return <div key={index}><p className={props.style_label} htmlFor={key}>{translate[key]} </p>{props.profile ? (<input className={props.style_input} id={key} name={key} defaultValue={value} onChange={(e) => handleChange(e)}/>) : (<input className={props.style_input} id={key} name={key} value={value} readOnly/>)}</div>
                } else {
                    return <div key={index}><label htmlFor={key}>{translate[key]} className={props.style_label}</label>{props.profile ? (<input className={props.style_input} id={key} name={key} defaultValue={value} onChange={(e) => handleChange(e)}/>) : (<input className={props.style_input} id={key} name={key} value={value} readOnly/>)}</div>
                }
            })}
            {Object.entries(props.form.special_fields).map(([key,value],index) => {
                if (["accepted","mutas","standard","wheelchair","ambulance"].includes(key)) {
                    return (
                        <div key={index}>
                            <label className={props.style_label} htmlFor={key}>{translate[key]}</label>
                            {props.profile ? (<input type='checkbox' id={key} name={key} className="checkbox" defaultChecked={value} onChange={(e) => handleChange(e)}/>) : (<input type='checkbox' id={key} name={key} className="checkbox" checked={value} readOnly/>)}
                        </div>
                    );
                }
                if (props.p) {
                    return <div key={index}><p className={props.style_label} htmlFor={key}>{translate[key]} </p>{props.profile ? (<input className={props.style_input} id={key} name={key} defaultValue={value} onChange={(e) => handleChange(e)}/>) : (<input className={props.style_input} id={key} name={key} value={value} readOnly/>)}</div>
                } else {
                    return <div key={index}><label htmlFor={key}>{translate[key]} className={props.style_label}</label>{props.profile ? (<input className={props.style_input} id={key} name={key} defaultValue={value} onChange={(e) => handleChange(e)}/>) : (<input className={props.style_input} id={key} name={key} value={value} readOnly/>)}</div>
                }
            })}
            <div className={props.style_buttons}>
                {props.methods.map((method,index) => {
                    if (method === "PATCH" && props.current_api_url.split('/').includes('cloud')) {
                        return <button key={index} type='button' id={method} className={props.style_button} onClick={() => handleClick(method)}>Accept Drive</button>
                    } else if (method === "DELETE" && props.current_api_url.split('/').includes('transportations')) {
                        return <button key={index} type='button' id={method} className={props.style_button} onClick={() => handleClick(method)}>Complete Drive</button>
                    }
                    return <button key={index} type='button' id={method} className={props.style_button} onClick={() => handleClick(method)}>{translate[method]}</button>
                })}
            </div>
        </form>
    );
}

export default Info;