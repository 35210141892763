import {React, useState, useEffect} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import '../style/login.css'
import NavBar from '../subcomponents/NavBar';
import Login from '../subcomponents/Login'

function UserService() {
    const location = useLocation();
    const user_service_url = location.state?.extraParam;
    const [userServiceData, setUserServiceData] = useState(null)
    const navigate = useNavigate();

    const handleClick = (key, value) => {
        navigate(`${key}/`,{ state: { extraParam: `${value}`, objects: userServiceData.objects } });
    }

    useEffect(() => {
        if (!user_service_url) {
            navigate('/');
            return;
        }
        fetch(user_service_url, {
            credentials: 'include',
            headers: {
              accept: "application/json"
            }
        }).then(async response => {
            const data = await response.json();
            if (response.status === 400) {
                navigate('/');
            } else {
                setUserServiceData(data);
            }
        });
    }, [user_service_url,navigate]);

    if (!userServiceData) {
        return (
            <p>Loading...</p>
        )
    } else {
        let title = window.location.href.split("/").includes("service") ? "Service" : "User";
        return (
            <div className='user-service-container'>
                <header className="user-service-title">
                    <h1>{title}</h1>
                </header>
                <img src="/images/CabCloudLogo.WebP" alt="Logo"/>
                <NavBar references={userServiceData.nav_references}/>
                <main className='login'>
                    <h1 className="login-label">Log in</h1>
                    <Login form={userServiceData.forms[0]} current_api_url={user_service_url} objects={userServiceData.objects}/>
                    <button className='new-account' onClick={() => handleClick(Object.keys(userServiceData)[1],userServiceData.new)}> I don't have an account yet.</button>
                </main>
            </div>
        );
    }
}

export default UserService;