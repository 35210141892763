import { React, useState } from 'react'
import { useNavigate } from 'react-router-dom';

function replaceLastSegment(url, newSegment) {
    const parts = url.split('/');
    parts[parts.length - 2] = newSegment;
    return parts.join('/');
}

function Form(props) {
    const translate = {'u_id': "ID", 'ts_id': "ID", 'r_id': "ID", 'GET': "Find", 'POST': "Submit", 'PATCH': "Update", 'DELETE': "Delete", "address_org": "Pick-up address", "address_dest": "Drop off address", "tel": "Tel.", "timestamp_of_pickup": "Pick-up Time", "email": "E-mail","comment": "Comments", "driveid": "DriveID", "password": "Password",'name': "Name", "first_name": "First Name", "last_name": "Last Name", "address": "Address", "mutas": "Mutas", "transportation_service": "Transportation Type", "accepted": "Accepted", "standard": "Standard Transport", "wheelchair": "Wheelchair Transport", "ambulance": "Ambulance Transport"} 
    const navigate = useNavigate();
    const [emailErrorData, setEmailErrorData] = useState("");
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const jsonData = {}
        for (const [name, value] of formData.entries()) {
            jsonData[name] = value
        }
        fetch(`${props.current_api_url}`, {
            method: props.form.method,
            credentials: "include",
            headers: {
                'Content-type': 'application/json',
                accept: 'application/json'
            },
            body: JSON.stringify(jsonData)
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error("Error in response...");
            }
        }).then(data => {
            setEmailErrorData('');
            if (window.location.pathname.split('/').includes("new-drive")) {
                navigate(replaceLastSegment(window.location.pathname,`drives/${data.id}`), {state: {extraParam: data.link}});
            }
            else if (window.location.pathname.split('/').includes("new")) {
                navigate(replaceLastSegment(window.location.pathname,data.id), {state: {extraParam: data.link}});
            } else {
                navigate(`${window.location.pathname}${data.id}/`,{ state: { extraParam: data.link} });
            }
        }).catch(error => {
            setEmailErrorData("Please enter a valid email.")
            console.log(`Something went wrong: ${error}`);
        })
    }
    
    return (
        <form className={props.style_form} action={window.location.href} method={props.form.method} onSubmit={(e) => handleSubmit(e)}>
            {Object.entries(props.form.required_fields).map(([key,value],index) => {
                if (value === "") {
                    return props.p ? (
                        <div key={index}>
                            <p className={props.style_label} htmlFor={key}>{translate[key]+"*"} </p><input type={key === "email" ? key : "text"} className={props.style_input} id={key} name={key} placeholder={`Enter ${translate[key]}`} required/>
                            {key === "email" && (<div className='error'>{emailErrorData}</div>)}
                        </div>) : 
                        (<div key={index}>
                            <label className={props.style_label} htmlFor={key}>{translate[key]+"*"} </label><input type={key === "email" ? key : "text"} className={props.style_input} id={key} name={key} placeholder={`Enter ${translate[key]}`} required/>
                            {key === "email" && (<div className='error'>{emailErrorData}</div>)}
                        </div>)
                } 
                return props.p ? (<div key={index}><p className={props.style_label} htmlFor={key}>{translate[key]+"*"} </p><input type={key === "email" ? key : "text"} className={props.style_input} id={key} name={key} defaultValue={value} readOnly/></div>) : (<div key={index}><label className={props.style_label} htmlFor={key}>{translate[key]} </label><input type={key === "email" ? key : "text"} className={props.style_input} id={key} name={key} defaultValue={value} readOnly /></div>)
                
            })}
            {Object.entries(props.form.optional_fields).map(([key,value],index) => {
                if (value === "") {
                    return props.p ? (<div key={index}><p className={props.style_label} htmlFor={key}>{translate[key]} </p><input type={key === "email" ? key : "text"} className={props.style_input} id={key} name={key} placeholder={`Enter ${translate[key]}`}/></div>) : (<div key={index}><label className={props.style_label} htmlFor={key}>{translate[key]} </label><input type={key === "email" ? key : "text"} className={props.style_input} id={key} name={key} placeholder={`Enter ${translate[key]}`}/></div>)
                }
                return props.p ? (<div key={index}><p className={props.style_label} htmlFor={key}>{translate[key]} </p><input type={key === "email" ? key : "text"} className={props.style_input} id={key} name={key} defaultValue={value} readOnly/></div>) : (<div key={index}><label className={props.style_label} htmlFor={key}>{translate[key]} </label><input type={key === "email" ? key : "text"} className={props.style_input} id={key} name={key} defaultValue={value} readOnly/></div>)
            })}
            <p/>
            {Object.entries(props.form.special_fields).map(([key,value],index) => {
                return ["accepted","mutas","standard","wheelchair","ambulance"].includes(key) ? (
                    <div key={index}>
                        <label className={props.style_label} htmlFor={key}>{translate[key]}</label>
                        <input type='checkbox' id={key} name={key} className="checkbox"/>
                    </div>
                ) : (
                    <div key={index}>
                        <label className={props.style_label} htmlFor="transportation_service">Type Of Transport </label>
                        <select id="transportation_service" name="transportation_service" className='select-box'>
                            <option value="standard">Standard</option>
                            <option value="wheelchair">Wheelchair</option>
                            <option value="ambulance">Ambulance</option>
                        </select>
                    </div>
                );
            })}
            <button className={props.style_button} type='submit'>{translate[props.form.method]}</button>
        </form>
    );
}

export default Form;