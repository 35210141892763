import React, {useEffect, useState} from 'react'

import '../style/home.css'
import NavBar from '../subcomponents/NavBar';
import Form from '../subcomponents/Form';
import GetRoute from '../subcomponents/GetRoute';

const host_address = "https://groep23.webdev.ilabt.imec.be/api/";
//const host_address = "http://localhost:5000/";
function Home() {
  
  const [mainData, setMainData] = useState(null);

  useEffect(() => {
    fetch(host_address, {
      headers: {
        accept: "application/json"
      }
    }).then(
      response => response.json()
    ).then(
      data => {
        setMainData(data);
      }
    )
  }, []);

  if (!mainData) {
    return (
      <p>Loading...</p>
    )
  } else {
      return (
        <div className="home-container">
          <header className="home-title">
              <h1>Welcome to CabCloud</h1>
          </header>
          <img src="/images/CabCloudLogo.WebP" alt="Logo"></img>
          <NavBar references={mainData.nav_references}></NavBar>
          <main>
            <div className='left-side'>
              <div className='main-page-drive'>
                <h2 className='subtitle-label'>Plan your drive, FAST and EASY!</h2>
                <Form form={mainData.forms[0]} style_input='home-input' style_button='home-button' style_label='home-label-text' current_api_url={host_address} objects={mainData.routes} p={false}/>
              </div>
              <div className="main-page-find">
                <h2 className='subtitle-label'>Find your drive!</h2>
                <GetRoute form={mainData.forms[1]} style_input='home-input' style_button='home-button' style_label='home-label-text' current_api_url={host_address} objects={mainData.routes}/>
              </div>
            </div>
            <div className="main-page-intro">
            <h3 className="subtitle-label">CabCloud is THE place to order a cab that's well adjusted to YOUR needs.</h3>
                <p>Our purpose is to make booking transport easier. No more long conversations<br/>with your taxi service. No more miscommunications and no more doubts.<br/>CabCloud is here! </p>
                <p>Having trouble finding an easy transport for you?<br/>Then this is your cue to use CabCloud. Transport must be a basic<br/>necessity for <span className='bold'>EVERYONE!</span></p>
            </div>
          </main>
        </div>
      );
  }
}

export default Home;
