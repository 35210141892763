import {React, useState, useEffect} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

import NavBar from '../subcomponents/NavBar';
import Info from '../subcomponents/Info';

import '../style/routeid.css'

function ServiceIdTransportationsId() {
    const location = useLocation();
    const serviceid_transportationsid_url = location.state?.extraParam;
    const [serviceIdTransportationsIdData, setServiceIdTransportationsIdData] = useState(null)
    const navigate = useNavigate();

  useEffect(() => {
    if (!serviceid_transportationsid_url) {
      navigate('/');
      return;
    }
    fetch(serviceid_transportationsid_url, {
      credentials: 'include',
      headers: {
        accept: "application/json"
      }
    }).then(async response => {
      const data = await response.json();
      if (response.status === 400) {
        navigate('/');
      } else {
        setServiceIdTransportationsIdData(data);
      }
    });
  }, [serviceid_transportationsid_url,navigate]);

  if (!serviceIdTransportationsIdData) {
    return (
      <p>Loading...</p>
    )
  } else {
        return (
            <div className='routeid-container'>
                <header className='routeid-title'>
                    <h1>Route Information</h1>
                </header>
                <img src="/images/CabCloudLogo.WebP" alt="Logo"/>
                <NavBar references={serviceIdTransportationsIdData.nav_references}/>
                <main className='routeid-drive'>
                    <Info current_api_url={serviceid_transportationsid_url} form={serviceIdTransportationsIdData.forms[0]} style_input='routeid-input' style_button='routeid-button' style_label='routeid-label-text' p={true} methods={['DELETE']}/>
                </main>
            </div>
        );
  }
}

export default ServiceIdTransportationsId;