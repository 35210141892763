import {React, useState, useEffect} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import '../style/serviceid.css'

import NavBar from '../subcomponents/NavBar'
import BigButton from '../subcomponents/BigButton';

function ServiceId() {
    const location = useLocation();
    const service_id_url = location.state?.extraParam;
    const [serviceIdData, setServiceIdData] = useState(null)
    const navigate = useNavigate();

    useEffect(() => {
        if (!service_id_url) {
            navigate('/');
            return;
        }
        fetch(service_id_url, {
            headers: {
                accept: "application/json"
            },
            credentials: 'include',
        }).then(async response => {
            const data = await response.json();
            if (response.status === 400) {
                navigate('/');
            } else {
                setServiceIdData(data);
            }
        });
    }, [service_id_url,navigate]);

    if (!serviceIdData) {
        return (
            <p>Loading...</p>
        )
    } else {
        return (
            <div className='serviceid-container'>
                <header className='serviceid-title'>
                    <h1>
                        Welcome, {serviceIdData.service.ts_name}
                    </h1>
                </header>
                <img src="/images/CabCloudLogo.WebP" alt="Logo"/>
                <NavBar references={serviceIdData.nav_references} current_api_url={service_id_url}/>
                <main className='id-buttons'>
                    <BigButton title="The CLOUD" text="Click here to access the cloud for new rides!" site_link="cloud" api_link={serviceIdData.cloud} style_button="serviceid-cloud" style_text="serviceid-style-text" style_subtitle="serviceid-style-subtitle"/>
                    <BigButton title="Transportations" text="Click here to access your transportations!" site_link="transportations" api_link={serviceIdData.transportations} style_button="serviceid-transportations" style_text="serviceid-style-text" style_subtitle="serviceid-style-subtitle"/>
                </main>
            </div>
        );
    }
}

export default ServiceId