import {React, useState, useEffect} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import '../style/userid.css'

import NavBar from '../subcomponents/NavBar'
import BigButton from '../subcomponents/BigButton';

function UserId() {
    const location = useLocation();
    const user_id_url = location.state?.extraParam;
    const [userIdData, setUserIdData] = useState(null)
    const navigate = useNavigate();
    useEffect(() => {
        if (!user_id_url) {
            navigate('/');
            return;
        }
        fetch(user_id_url, {
            credentials: 'include',
            headers: {
              accept: "application/json"
            }
        }).then(async response => {
            const data = await response.json();
            if (response.status === 400) {
                navigate('/');
            } else {
                setUserIdData(data);
            }
        });
    }, [user_id_url,navigate]);

    if (!userIdData) {
        return (
            <p>Loading...</p>
        )
    } else {
        return (
            <div className='userid-container'>
                <header className='userid-title'>
                    <h1>
                        Welcome, {userIdData.user.u_first_name}
                    </h1>
                </header>
                <img src="/images/CabCloudLogo.WebP" alt="Logo"/>
                <NavBar references={userIdData.nav_references} current_api_url={user_id_url}/>
                <main className='id-buttons'>
                    <BigButton title="New Drive" text="Click here to save a new drive to your profile!" site_link="new-drive" api_link={userIdData["new-drive"]} style_button="userid-new-ride" style_text="userid-style-text" style_subtitle="userid-style-subtitle"/>
                    <BigButton title="Planned Drives" text="Click here to access your planned drives!" site_link="drives" api_link={userIdData.drives} style_button="userid-saved-rides" style_text="userid-style-text" style_subtitle="userid-style-subtitle"/>
                </main>
            </div>
        );
    }
}

export default UserId;