import {React, useState, useEffect} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

import NavBar from '../subcomponents/NavBar';
import Info from '../subcomponents/Info';

import '../style/routeid.css'

function CloudId() {
    const location = useLocation();
    const cloudid_url = location.state?.extraParam;
    const [cloudIdData, setCloudIdData] = useState(null)
    const navigate = useNavigate();

  useEffect(() => {
    if (!cloudid_url) {
      navigate('/');
      return;
    }
    fetch(cloudid_url, {
      credentials: 'include',
      headers: {
        accept: "application/json"
      }
    }).then(async response => {
      const data = await response.json();
      if (response.status === 400) {
          navigate('/');
      } else {
          setCloudIdData(data);
      }
  });
  }, [cloudid_url,navigate]);

  if (!cloudIdData) {
    return (
      <p>Loading...</p>
    )
  } else {
        return (
            <div className='routeid-container'>
                <header className='routeid-title'>
                    <h1>Route Information</h1>
                </header>
                <img src="/images/CabCloudLogo.WebP" alt="Logo"/>
                <NavBar references={cloudIdData.nav_references}/>
                <main className='routeid-drive'>
                    <Info ts_id={cloudIdData.ts_id} id={cloudIdData.r_id} form={cloudIdData.forms[0]} style_input='routeid-input' style_label='routeid-label-text' style_button='routeid-button' p={true} methods={['PATCH']} current_api_url={cloudid_url}/>
                </main>
            </div>
        );
  }
}

export default CloudId;