import React from 'react';
import { useNavigate } from 'react-router-dom'

function makeNice(string) {
  string = string.replaceAll("_"," ")
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function NavBar(props) {
    const navigate = useNavigate();
    const current_api_url = props.current_api_url;
    const handleClick = (key,value) => {
      if (key === "Return") {
        navigate(`${window.location.pathname.split('/').slice(0,-2).join('/')}/`,{ state: { extraParam: value } });
      } else if (key === "log-out") {
        fetch(current_api_url, {
          method: "DELETE",
          headers: {
            accept: "application/json"
          },
          credentials: "include",
        }).then(response => {
          if (response.ok) {
            navigate("/");
          } else {
            console.log("Failed to log out...")
          }
        })
      } else {
        navigate(`${key}/`,{ state: { extraParam: value } });
      }
    }
    return (
      <nav>
        <ul>
            {Object.entries(props.references).map(([key, value], index) => {
              if (key === 'index') {
                key = 'Return'; 
              }
              if (key === "about") {
                return <li key={index}><button className='nav-button' onClick={() => handleClick(key,value)}>About Us</button></li>
              } else if (key === "service") {
                return <li key={index}><button className='nav-button' onClick={() => handleClick(key,value)}>Services</button></li>
              } else {
                return <li key={index}><button className='nav-button' onClick={() => handleClick(key,value)}>{makeNice(key)}</button></li>
              }
            })}
        </ul>
      </nav>
    );
} 

export default NavBar;