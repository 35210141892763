import {React, useState, useEffect} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

import NavBar from '../subcomponents/NavBar'
import List from '../subcomponents/List'

import '../style/list.css'

function Cloud() {
    const location = useLocation();
    const cloud_url = location.state?.extraParam;
    const [cloudData, setCloudData] = useState(null)
    const navigate = useNavigate();
    useEffect(() => {
        if (!cloud_url) {
            navigate('/');
            return;
        }
        fetch(cloud_url, {
            credentials: 'include',
            headers: {
              accept: "application/json"
            }
        }).then(async response => {
            const data = await response.json();
            if (response.status === 400) {
                navigate('/');
            } else {
                setCloudData(data);
            }
        });
    }, [cloud_url,navigate]);

    if (!cloudData) {
        return (
            <p>Loading...</p>
        )
    } else {
        return (
            <div className='list-container'>
                <header className='list-title'>
                    <h1>The CLOUD</h1>
                </header>
                <img src="/images/CabCloudLogo.WebP" alt="Logo"/>
                <NavBar references={cloudData.nav_references}/>
                <main>
                    <List references={cloudData.list_references} text={"There are no routes."}/>
                </main>
            </div>
        );
    }
}

export default Cloud;