import {React, useState, useEffect} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

import NavBar from '../subcomponents/NavBar';
import Info from '../subcomponents/Info';

import '../style/reviewid.css'

function ReviewId() {
  const location = useLocation();
  const reviewid_url = location.state?.extraParam;
  const [reviewIdData, setReviewIdData] = useState(null)
  const navigate = useNavigate();
  useEffect(() => {
    if (!reviewid_url) {
      navigate('/');
      return;
    }
    fetch(reviewid_url, {
      credentials: 'include',
      headers: {
        accept: "application/json"
      }
    }).then(async response => {
      const data = await response.json();
      if (response.status === 400) {
        navigate('/');
        return;
      } else {
        setReviewIdData(data);
      }
    });
  }, [reviewid_url,navigate]);

  if (!reviewIdData) {
    return (
      <p>Loading...</p>
    )
  } else {
    return (
        <div className='reviewid-container'>
            <header className='reviewid-title'>
                <h1>Review</h1>
            </header>
            <img src="/images/CabCloudLogo.WebP" alt="Logo"></img>
            <NavBar references={reviewIdData.nav_references}/>
            <main className='reviewid-review'>
                <Info current_api_url={reviewid_url} form={reviewIdData.forms[0]} style_input='reviewid-input' style_button='reviewid-button' style_label='reviewid-label-text' p={true} methods={[]}/>
            </main>
        </div>
    );
  }
}

export default ReviewId;