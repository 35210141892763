import {React, useState, useEffect} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

import '../style/routeid.css'
import NavBar from '../subcomponents/NavBar'
import Info from '../subcomponents/Info'

function UserIdRoutesCompletedId() {
    const location = useLocation();
    const userid_drives_completed_routeid_url = location.state?.extraParam;
    const [userIdRoutesCompletedIdData, setUserIdRoutesCompletedIdData] = useState(null)
    const navigate = useNavigate();
    const handleClick = (value) => {
        navigate(`${window.location.pathname}review/`, {state: {extraParam: value}});
    }
    useEffect(() => {
        if (!userid_drives_completed_routeid_url) {
            navigate('/');
            return;
        }
        fetch(userid_drives_completed_routeid_url, {
            credentials: 'include',
            headers: {
              accept: "application/json"
            }
        }).then(async response => {
            const data = await response.json();
            if (response.status === 400) {
                navigate('/');
            } else {
                setUserIdRoutesCompletedIdData(data);
            }
        });
    }, [userid_drives_completed_routeid_url,navigate]);

    if (!userIdRoutesCompletedIdData) {
        return (
            <p>Loading...</p>
        )
    } else {
        return (
            <div className='routeid-container'>
                <header className='routeid-title'>
                    <h1>Route Info</h1>
                </header>
                <img src="/images/CabCloudLogo.WebP" alt="Logo"></img>
                <NavBar references={userIdRoutesCompletedIdData.nav_references}/>
                <main className='routeid-drive'>
                    <Info form={userIdRoutesCompletedIdData.forms[0]} style_input='routeid-input' style_label='routeid-label-text' p={true} methods={[]} accepted={true}/>
                    {userIdRoutesCompletedIdData.review ? (
                        <button className='routeid-button' onClick={() => handleClick(userIdRoutesCompletedIdData.review_reference)}>{'★'.repeat(userIdRoutesCompletedIdData.review.rv_rating)}</button>
                    ) : 
                        <button className='routeid-button' onClick={() => handleClick(userIdRoutesCompletedIdData.review_reference)}>Not Reviewed</button>
                    }
                </main>
            </div>
        )
    }
}

export default UserIdRoutesCompletedId;