import {React, useState, useEffect} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

import NavBar from '../subcomponents/NavBar'
import List from '../subcomponents/List'

import '../style/list.css'

function UserIdPlannedRoutes() {
    const location = useLocation();
    const userid_planned_routes_url = location.state?.extraParam;
    const [userIdPlannedRoutesData, setUserIdPlannedRoutesData] = useState(null)
    const navigate = useNavigate();
    useEffect(() => {
        if (!userid_planned_routes_url) {
            navigate('/');
            return;
        }
        fetch(userid_planned_routes_url, {
            credentials: 'include',
            headers: {
              accept: "application/json"
            }
        }).then(async response => {
            const data = await response.json();
            if (response.status === 400) {
                navigate('/');
            } else {
                setUserIdPlannedRoutesData(data);
            }
        });
    }, [userid_planned_routes_url,navigate]);

    if (!userIdPlannedRoutesData) {
        return (
            <p>Loading...</p>
        )
    } else {
        return (
            <div className='list-container'>
                <header className='list-title'>
                    <h1>My Drives</h1>
                </header>
                <img src="/images/CabCloudLogo.WebP" alt="Logo"/>
                <NavBar references={userIdPlannedRoutesData.nav_references}/>
                <main>
                    <List references={userIdPlannedRoutesData.list_references} text={"You currently have no planned rides."}/>
                </main>
            </div>
        );
    }
}

export default UserIdPlannedRoutes;