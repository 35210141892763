import {React, useState, useEffect} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

import NavBar from '../subcomponents/NavBar';
import Info from '../subcomponents/Info';

import '../style/routeid.css'

function ServiceIdTransportationsCompletedId() {
    const location = useLocation();
    const serviceid_transportationsid_completed_url = location.state?.extraParam;
    const [serviceIdTransportationsCompletedIdData, setServiceIdTransportationsCompletedIdData] = useState(null)
    const navigate = useNavigate();

  useEffect(() => {
    if (!serviceid_transportationsid_completed_url) {
      navigate('/');
      return;
    }
    fetch(serviceid_transportationsid_completed_url, {
      credentials: 'include',
      headers: {
        accept: "application/json"
      }
    }).then(async response => {
      const data = await response.json();
      if (response.status === 400) {
        navigate('/');
      } else {
        setServiceIdTransportationsCompletedIdData(data);
      }
    });
  }, [serviceid_transportationsid_completed_url,navigate]);

  if (!serviceIdTransportationsCompletedIdData) {
    return (
      <p>Loading...</p>
    )
  } else {
        return (
            <div className='routeid-container'>
                <header className='routeid-title'>
                    <h1>Route Information</h1>
                </header>
                <img src="/images/CabCloudLogo.WebP" alt="Logo"></img>
                <NavBar references={serviceIdTransportationsCompletedIdData.nav_references}/>
                <main className='routeid-drive'>
                    <Info current_api_url={serviceid_transportationsid_completed_url} form={serviceIdTransportationsCompletedIdData.forms[0]} style_input='routeid-input' style_button='routeid-button' style_label='routeid-label-text' p={true} methods={[]}/>
                    {serviceIdTransportationsCompletedIdData.review && (
                        <div>
                            <h2>Review</h2>
                            <div>
                                <p className='routeid-label-text'>Rating</p>
                                <input className='routeid-input' defaultValue={'★'.repeat(serviceIdTransportationsCompletedIdData.review.rv_rating)} readOnly/>
                                <p className='routeid-label-text'>Comment</p>
                                <input className='routeid-input' defaultValue={serviceIdTransportationsCompletedIdData.review.rv_content} readOnly/>
                            </div>
                        </div>
                    )}
                </main>
            </div>
        );
  }
}

export default ServiceIdTransportationsCompletedId;