import {React, useState, useEffect} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

import NavBar from '../subcomponents/NavBar'
import Form from '../subcomponents/Form'

import '../style/newCustomer.css'

function ServiceIdCustomersNew() {
    const location = useLocation();
    const serviceid_customers_new_url = location.state?.extraParam;
    const [serviceIdCustomersNewData, setServiceIdCustomersNewDataData] = useState(null)
    const navigate = useNavigate();
    useEffect(() => {
        if (!serviceid_customers_new_url) {
            navigate('/');
            return;
        }
        fetch(serviceid_customers_new_url, {
            credentials: 'include',
            headers: {
              accept: "application/json"
            }
        }).then(async response => {
            const data = await response.json();
            if (response.status === 400) {
                navigate('/');
            } else {
                setServiceIdCustomersNewDataData(data);
            }
        });
    }, [serviceid_customers_new_url,navigate]);

    if (!serviceIdCustomersNewData) {
        return (
            <p>Loading...</p>
        )
    } else {
        return (
            <div className='newcustomer-container'>
                <header className='newcustomer-title'>
                    <h1>
                        Add Client
                    </h1>
                </header>
                <img src="/images/CabCloudLogo.WebP" alt="Logo"/>
                <NavBar references={serviceIdCustomersNewData.nav_references}/>
                <main className='newcustomer-client'>
                    <Form form={serviceIdCustomersNewData.forms[0]} style_input='newcustomer-input' style_button='newcustomer-button' style_label='newcustomer-label-text' current_api_url={serviceid_customers_new_url} p={true}/>
                </main>
            </div>
        );
    }
}

export default ServiceIdCustomersNew;