import { React, useState } from "react";
import { useNavigate } from 'react-router-dom';

function Signup(props) {
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const navigate = useNavigate();
    const translate = {'u_id': "ID", 'ts_id': "ID", 'r_id': "ID", 'GET': "Find", 'POST': "Submit", 'PATCH': "Update", 'DELETE': "Delete", "address_org": "Pickup address", "address_dest": "Dropoff address", "tel": "Tel.", "timestamp_of_pickup": "Pick-Up Time", "email": "E-mail","comment": "Comments", "driveid": "DriveID", "password": "Password",'name': "Name", "first_name": "First Name", "last_name": "Last Name", "address": "Address", "mutas": "Mutas", "transportation_service": "Transportation Type", "accepted": "Accepted", "standard": "Standard Transport", "wheelchair": "Wheelchair Transport", "ambulance": "Ambulance Transport"};
    const handleSubmit = async (e) => {
        e.preventDefault();

        // reset errors
        setEmailError('');
        setPasswordError('');
        
        // get values
        const formData = new FormData(e.target);
        const jsonData = {};
        jsonData['email'] = formData.get('email');
        jsonData['password'] = formData.get('password');
        if (window.location.pathname.split('/').includes("service")) {
            jsonData['name'] = formData.get('name');
            jsonData['address'] = formData.get('address');
            jsonData['tel'] = formData.get('tel');
            jsonData['wheelchair'] = formData.get('wheelchair');
            jsonData['ambulance'] = formData.get('ambulance');
            jsonData['standard'] = formData.get('standard');
        } else {
            jsonData['first_name'] = formData.get('first_name');
            jsonData['last_name'] = formData.get('last_name');
            jsonData['main_address'] = formData.get('address');
            jsonData['tel'] = formData.get('tel');
            jsonData['mutas'] = formData.get('mutas');
        }
        
        try {
            await fetch(props.current_api_url, {
                method: "POST",
                body: JSON.stringify(jsonData),
                credentials: "include",
                headers: { 'Content-Type': 'application/json' }
            }).then(response => {
                return response.json();
            }).then(data => {
                if (data.errors) {
                    if (window.location.pathname.split('/').includes("service")) {
                        setEmailError(data.errors.ts_email);
                        setPasswordError(data.errors.ts_password);
                    } else {
                        setEmailError(data.errors.u_email);
                        setPasswordError(data.errors.u_password);
                    }
                }
                if (data.objectid) {
                    navigate(`${window.location.pathname.split('/').slice(0,-2).join('/')}/${data.objectid}/`,{ state: { extraParam: data.link} });
                }
                
            });
        } catch (err) {
            console.log(err);
        }
    }
    
    return (
        <form onSubmit={(e) => handleSubmit(e)}>
            {Object.entries(props.form.required_fields).map(([key,value],index) => {
                return (
                    <div key={index}>
                        <p className='new-account-label' htmlFor={key}>{translate[key]+"*"} </p><input className='new-account-input' id={key} name={key} type={(key === "password" || key === "email") ? key : "text"} placeholder={`Enter ${translate[key]}`} required/>
                        {key === "email" && <div className="signup-error">{emailError}</div>}  
                        {key === "password" && <div className="signup-error">{passwordError}</div>}  
                    </div>
                );
            })}
            {Object.entries(props.form.optional_fields).map(([key,value],index) => {
                return (
                    <div key={index}>
                        <p className='new-account-label' htmlFor={key}>{translate[key]} </p><input className='new-account-input' id={key} name={key} type={(key === "password" || key === "email") ? key : "text"} placeholder={`Enter ${translate[key]}`}/>
                    </div>
                );
            })}
            {Object.entries(props.form.special_fields).map(([key,value],index) => {
                return (
                    <div key={index}>
                        <label className='new-account-label' htmlFor={key}>{translate[key]}</label>
                        <input type='checkbox' id={key} name={key} className="checkbox"/>
                    </div>
                ) 
            })}
            <button className='new-account-button' type='submit'>Submit</button>
        </form>
    );
}

export default Signup;