import { React, useState } from "react"
import { useNavigate } from 'react-router-dom';

function GetRoute(props) {
    const [idErrorData, setIdErrorData] = useState("");
    const translate = {'u_id': "ID", 'ts_id': "ID", 'r_id': "ID", 'GET': "Find", 'POST': "Submit", 'PATCH': "Update", 'DELETE': "Delete", "address_org": "Pickup address", "address_dest": "Dropoff address", "tel": "Tel.", "timestamp_of_pickup": "Pick-Up Time", "email": "E-mail","comment": "Comments", "driveid": "DriveID", "password": "Password",'name': "Name", "first_name": "First Name", "last_name": "Last Name", "address": "Address", "mutas": "Mutas", "transportation_service": "Transportation Type", "accepted": "Accepted", "standard": "Standard Transport", "wheelchair": "Wheelchair Transport", "ambulance": "Ambulance Transport"} 
    const navigate = useNavigate();
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const jsonData = {}
        for (const [name, value] of formData.entries()) {
            jsonData[name] = value
        };
        const id = document.getElementById(Object.keys(jsonData)[0]).value;
        const id_url = props.objects.find(obj => obj.split('/')[obj.split('/').length - 2] === id);
        if (!id) {
            setIdErrorData('Please enter a drive id.');
            console.error('Error occurred while getting drive:', idErrorData);
        } else if (!id_url) {
            setIdErrorData('There is no drive with this id.');
            console.error('Failed to get drive.');
        } else {
            setIdErrorData('');
            navigate(`/${id}/`, { state: { extraParam: id_url} });   
        }
    }

    return (
        <form className={props.style_form} action={window.location.href} method={props.form.method} onSubmit={(e) => handleSubmit(e)}>
            {Object.entries(props.form.required_fields).map(([key,value],index) => {
                return (
                    <div key={index}>
                        <label className={props.style_label} htmlFor={key}>{translate[key]+"*"} </label><input className={props.style_input} id={key} name={key} placeholder={`Enter ${translate[key]}`}/>
                        <div className="id-error">{idErrorData}</div> 
                        <p></p>   
                    </div>
                );                
            })}
            <button className={props.style_button} type='submit'>{translate[props.form.method]}</button>
        </form>
    );
}

export default GetRoute;
