import React from 'react'
import { useNavigate } from 'react-router-dom';

function BigButton(props) {
    const navigate = useNavigate();
    const handleClick = (key,value) => {
        navigate(`${key}/`,{ state: { extraParam: value } });
    }

    return(
        <button className={props.style_button} onClick={() => handleClick(props.site_link,props.api_link)}>
            <header className={props.style_subtitle}>
                <h1>{props.title}</h1>
            </header>
            <p className={props.style_text}>{props.text}</p>
        </button>      
    )
}

export default BigButton;