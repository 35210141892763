import {React, useState, useEffect} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

import NavBar from '../subcomponents/NavBar';
import Info from '../subcomponents/Info';

import '../style/profile.css'

function ServiceIdCustomersId() {
    const location = useLocation();
    const serviceid_customersid_url = location.state?.extraParam;
    const [serviceIdCustomersIdData, setServiceIdCustomersIdData] = useState(null)
    const navigate = useNavigate();

  useEffect(() => {
    if (!serviceid_customersid_url) {
      navigate('/');
      return;
    }
    fetch(serviceid_customersid_url, {
      credentials: 'include',
      headers: {
        accept: "application/json"
      }
    }).then(async response => {
      const data = await response.json();
      if (response.status === 400) {
        navigate('/');
      } else {
        setServiceIdCustomersIdData(data);
      }
  });
  }, [serviceid_customersid_url,navigate]);

  if (!serviceIdCustomersIdData) {
    return (
      <p>Loading...</p>
    )
  } else {
      return (
        <div className='profile-container'>
            <header className='profile-title'>
                <h1>Customer Profile</h1>
            </header>
            <img src="/images/CabCloudLogo.WebP" alt="Logo"/>
            <NavBar references={serviceIdCustomersIdData.nav_references}/>
            <main className='profile-info'>
                <Info id={serviceIdCustomersIdData.u_id} form={serviceIdCustomersIdData.forms[0]}  style_label='profile-label-text' style_input="profile-input" style_buttons="profile-buttons" style_button="profile-button" p={true} methods={['DELETE']} current_api_url={serviceid_customersid_url}/>
            </main>
        </div>
      );
  }
}

export default ServiceIdCustomersId;