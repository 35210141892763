import {React} from 'react'
import { useNavigate } from 'react-router-dom';

function List(props) {
    const navigate = useNavigate();
    const handleClick = (k,v) => {
        navigate(`${window.location.pathname}${k}/`,{ state: { extraParam: v } })
    }
    if (Object.keys(props.references).length === 0) {
        return (
            <div className='list-no-elements'><p className='list-no-elements-label'>{props.text}</p></div>
        )
    }
    return (
        <div className='list'>
            {props.references.map((element,index) => {
                return (
                    <li key={index} className='list-elements'>
                        <button className='list-elements-button' onClick={() => handleClick(element.id,element.link)}>{element.text}</button>
                    </li>
                );
            })}
        </div>
    );
}

export default List;