import {React, useState, useEffect} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

import NavBar from '../subcomponents/NavBar'
import List from '../subcomponents/List'

import '../style/list.css'

function UserIdRoutesCompleted() {
    const location = useLocation();
    const userid_routes_completed_url = location.state?.extraParam;
    const [userIdRoutesCompletedData, setUserIdRoutesCompletedData] = useState(null)
    const navigate = useNavigate();
    useEffect(() => {
        if (!userid_routes_completed_url) {
            navigate('/');
            return;
        }
        fetch(userid_routes_completed_url, {
            credentials: 'include',
            headers: {
              accept: "application/json"
            }
        }).then(async response => {
            const data = await response.json();
            if (response.status === 400) {
                navigate('/');
            } else {
                setUserIdRoutesCompletedData(data);
            }
        });
    }, [userid_routes_completed_url,navigate]);

    if (!userIdRoutesCompletedData) {
        return (
            <p>Loading...</p>
        )
    } else {
        return (
            <div className='list-container'>
                <header className='list-title'>
                    <h1>Completed Drives</h1>
                </header>
                <img src="/images/CabCloudLogo.WebP" alt="Logo"></img>
                <NavBar references={userIdRoutesCompletedData.nav_references}/>
                <main>
                    <List references={userIdRoutesCompletedData.list_references} text={"You currently have no completed rides."}/>
                </main>
            </div>
        );
    }
}

export default UserIdRoutesCompleted;