import {React, useState, useEffect} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import '../style/newAccount.css'

import NavBar from '../subcomponents/NavBar';
import Signup from '../subcomponents/Signup';

function UserServiceNew() {
    const location = useLocation();
    const user_service_new_url = location.state?.extraParam;
    const objects = location.state?.objects;
    const [userServiceNewData, setUserServiceNewData] = useState(null)
    const navigate = useNavigate();

    useEffect(() => {
        if (!user_service_new_url) {
            navigate('/');
            return;
        }
        fetch(user_service_new_url, {
            credentials: 'include',
            headers: {
              accept: "application/json"
            }
        }).then(async response => {
            const data = await response.json();
            if (response.status === 400) {
                navigate('/');
            } else {
                setUserServiceNewData(data);
            }
        });
    }, [user_service_new_url,navigate]);

    let title = window.location.pathname.split('/').includes("service") ? "Service" : "User"; 

    if (!userServiceNewData) {
        return (
            <p>Loading...</p>
        )
    } else {
        return (
            <div className='new-account-container'>
                <header className='new-account-title'>
                    <h1>Sign Up - New {title}</h1>
                </header>
                <img src="/images/CabCloudLogo.WebP" alt="Logo"/>
                <NavBar references={userServiceNewData.nav_references}/>
                <main className='sign-up'>
                    <Signup form={userServiceNewData.forms[0]} current_api_url={user_service_new_url} objects={objects}/>
                </main>
            </div>
        );
    }
}

export default UserServiceNew;