import {React, useState, useEffect} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

import '../style/profile.css'
import NavBar from '../subcomponents/NavBar';
import Info from '../subcomponents/Info';

function Profile() {
    const location = useLocation();
    const profile_url = location.state?.extraParam;
    const [profileData, setProfileData] = useState(null);
    const navigate = useNavigate();

  useEffect(() => {
    if (!profile_url) {
      navigate('/');
      return;
    }
    fetch(profile_url, {
      credentials: 'include',
      headers: {
        accept: "application/json"
      }
    }).then(async response => {
      const data = await response.json();
      if (response.status === 400) {
          navigate('/');
      } else {
          setProfileData(data);
      }
  });
  }, [profile_url,navigate]);

  if (!profileData) {
    return (
      <p>Loading...</p>
    )
  } else {
      return (
        <div className='profile-container'>
            <header className='profile-title'>
                <h1>Profile</h1>
            </header>
            <img src="/images/CabCloudLogo.WebP" alt="Logo"/>
            <NavBar references={profileData.nav_references}/>
            <main className='profile-info'>
                <Info id={profileData.id} form={profileData.forms[0]} style_input="profile-input" style_buttons="profile-buttons" style_button="profile-button" style_label='profile-label-text' p={true} profile={true} methods={['PATCH','DELETE']} current_api_url={profile_url}/>
            </main>
        </div>
      );
  }
}

export default Profile;